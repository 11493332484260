<template>
  <v-card
    min-height="550"
    class="rounded-10 d-flex flex-column fullwidth align-stretch"
  >
    <v-card-text>
      <v-row dense align="center" justify="center">
        <v-col :cols="mdAndUp ? 4 : 12">
          <v-sheet
            elevation="3"
            color="primary lighten-5 rounded-10"
            height="60"
            :class="mdAndUp ? 'justify-center' : 'justify-space-between px-5'"
            class="d-flex fullwidth align-center"
          >
            <span class="fw-500 fs-14 mr-2 primary--text">Total Balance :</span>
            <span class="fw-500 fs-16 primary--text">
              {{ balance_both | money }}
            </span>
          </v-sheet>
        </v-col>
        <v-col :cols="mdAndUp ? 4 : 12">
          <v-sheet
            elevation="3"
            color="error lighten-3 rounded-10"
            height="60"
            :class="mdAndUp ? 'justify-center' : 'justify-space-between px-5'"
            class="d-flex fullwidth align-center"
          >
            <span class="fw-500 fs-14 mr-2 primary--text">
              Pending Balance :
            </span>
            <span class="fw-500 fs-16 primary--text">
              {{ balance_pending | money }}
            </span>
          </v-sheet>
        </v-col>
        <v-col :cols="mdAndUp ? 4 : 12">
          <v-sheet
            elevation="3"
            color="success lighten-5 rounded-10"
            height="60"
            :class="mdAndUp ? 'justify-center' : 'justify-space-between px-5'"
            class="d-flex fullwidth align-center"
          >
            <span class="fw-500 fs-14 mr-2 primary--text">
              Available Balance :
            </span>
            <span class="fw-500 fs-16 primary--text">
              {{ balance_available | money }}
            </span>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card flat class="fill-height fullwidth" min-height="100%">
      <router-view :key="$route.fullPath"></router-view>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import request from '@/services/axios_instance'
export default {
  data: () => ({
    wallet: null,
    menus: [
      {
        text: 'History',
        icon: 'mdi-history',
        route: { name: 'wallet-history' }
      },
      {
        text: 'Withdraw',
        icon: 'mdi-cash-minus',
        route: { name: 'wallet-withdraw' }
      }
    ]
  }),
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('wallet', [
      'balance_both',
      'balance_pending',
      'balance_available'
    ])
  },
  created() {
    this.getWallet()
  },
  methods: {
    ...mapActions('wallet', ['getWallet'])
  }
}
</script>

<style></style>
